import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Пример асинхронного действия для получения списка оборудования
export const fetchHardware = createAsyncThunk(
    'hardware/fetchHardwareStatus',
    async ({userId}, { rejectWithValue }) => {
        console.log('🎈 fetchHardware');
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/getHardware`, {
                params: {
                  user_id : userId
                }
              });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const deleteTagFromHardware = createAsyncThunk(
    'hardware/deleteTagFromHardwareStatus',
    async ({ tag_id, hardware_id }, thunkAPI) => {
        console.log('🎈 deleteTagFromHardware');
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteTagFromHardware`, {
                data: { hardware_id, tag_id },
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


export const addNewHardware = createAsyncThunk(
    'showers/addNewHardwareSatus',
    async ({ hardwareData }, { rejectWithValue }) => {
        try {

            // Отправляем POST-запрос на сервер с новым именем душевой кабины
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewHardware`, {
                hardwareData: hardwareData,

            });
            console.log('hard response.data = ', response.data);
            return response.data; // Возвращаем данные из ответа сервера 
        } catch (error) {
            // В случае ошибки возвращаем отклоненное значение
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateHardware = createAsyncThunk(
    'glass/updateHardwareStatus',
    async ({ hardwareData }, thunkAPI) => {
        console.log('🎈 updateHardware');
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateHardware`, {
                hardwareData: hardwareData,

            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const addTagToHardware = createAsyncThunk(
    'glass/updateAddTagToHardwareStatus',
    async ({ tag_id, hardware_id, name }, thunkAPI) => {
        console.log('🎈 addTagToHardware');
        console.log('tag_id = ', tag_id);
        console.log('hardware_id = ', hardware_id);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addTagToHardware`, {
                tag_id,
                hardware_id,
                name
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

export const deleteHardware = createAsyncThunk(
    'glass/deleteHardwareStatus',
    async ({ hardware_id }, thunkAPI) => {
        console.log('🎈 deleteHardware');
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteHardware`, {
                data: { hardware_id: hardware_id },
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);

// Создание слайса
const hardwareSlice = createSlice({
    name: 'hardwares',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {
        // Здесь могут быть обычные редьюсеры для синхронных действий
        addHardware: (state, action) => {
            state.items.push(action.payload);
        },
        removeHardware: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHardware.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addNewHardware.fulfilled, (state, action) => {
            state.items.push(action.payload)
        });
        builder.addCase(addTagToHardware.fulfilled, (state, action) => {
            state.loading = false;
            console.log('вызвался addTagToHardware');
            console.log('action.payload = ', action.payload);

            const index = state.items.findIndex(hardware => hardware.hardware_id === parseInt(action.payload.hardware_id, 10));
            console.log('---*** index = ', index);
            if (index !== -1) {
                state.items[index].tags.push(action.payload);
            }
        });
        builder.addCase(deleteTagFromHardware.fulfilled, (state, action) => {
            state.loading = false;
            console.log('вызвался deleteTagFromHardware');
            console.log('action.payload = ', action.payload);

            const index = state.items.findIndex(hardware => hardware.hardware_id === parseInt(action.payload.hardware_id, 10));
            if (index !== -1) {
                console.log('index = ', index);
                // Здесь должно быть state.items[index].tags, а не state.data[index].tags
                state.items[index].tags = state.items[index].tags.filter(tag => tag.tag_id !== parseInt(action.payload.tag_id));
            }
        });
        builder.addCase(deleteHardware.fulfilled, (state, action) => {
            state.loading = false;
            console.log('вызвался deleteHardware');
            const index = state.items.findIndex(hardware => hardware.hardware_id === parseInt(action.payload.hardware_id, 10));
            if (index !== -1) {
                // Создание нового массива без элемента, который нужно удалить
                state.items = [
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1)
                ];
            }
        });
        builder.addCase(fetchHardware.fulfilled, (state, action) => {
            state.loading = false;

            state.items = action.payload;
        });
        builder.addCase(fetchHardware.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        builder.addCase(updateHardware.fulfilled, (state, action) => {
            state.status = 'succeeded';
            const index = state.items.findIndex(hardware => hardware.hardware_id === parseInt(action.payload.hardware_id, 10));

            if (index !== -1) {
                state.items[index].name = action.payload.name;
                state.items[index].article = action.payload.article;
                state.items[index].specifications = action.payload.specifications;
                state.items[index].description = action.payload.description;
                state.items[index].link = action.payload.link;
                state.items[index].price = action.payload.price;
                state.items[index].supplier = action.payload.supplier;
            }
        })
    },

});

// Экспорт сгенерированных действий редьюсера
export const { addHardware, removeHardware } = hardwareSlice.actions;

// Экспорт редьюсера
export default hardwareSlice.reducer;
