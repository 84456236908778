import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllTags } from '../store/slice/hardwareTagsSlice';
import Tags from '../components/Tags';
import AddNewTag from '../components/AddNewTag';

const TagsPage = () => {
  const dispatch = useDispatch();
  const allTags = useSelector(selectAllTags);

  console.log('allTags = ', allTags);
  


  const [showAddNewTag, setShowAddNewTag] = useState(false);

  const handleClose = () => {
    setShowAddNewTag(false);
  };





  return (
    <>
      <Navbar></Navbar>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">

      <div className="pt-6">
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
             onClick={() => setShowAddNewTag(true)} >
            Добавить тег
          </button>
           {showAddNewTag && <AddNewTag onClose={handleClose} />} 
        </div>

      <div className='text-lg font-semibold pt-6'>Все теги</div>
      {allTags && allTags.length > 0 ? (
        allTags.map((tagItem, index) => (
          <div key={tagItem.tag_id}>
            <Tags
              propsName={tagItem.name} propsId ={tagItem.tag_id}
            />
          </div>
        ))
      ) : (
        <p>No  tags available.</p>
      )}

      </div>
    </>
  );
};

export default TagsPage;
