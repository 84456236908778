import React from 'react';
import { Link } from 'react-router-dom';
import { deleteShower } from '../store/slice/showerSlice';
import { useDispatch } from 'react-redux';

const ShowerCard = ({ title, description, img_url, note, cabin_id }) => {

  const imgUrl = process.env.REACT_APP_URL + '/' + img_url;
  const dispatch = useDispatch();


  const handleDeleteShower = () => {
    console.log('cabin_id  = ', cabin_id);
    const confirmDelete = window.confirm('Удалить кабину?');
    if (confirmDelete) {
      console.log('Удаляем кабину ');

      dispatch(deleteShower({ cabin_id: cabin_id }));
    } else {
      console.log('Удаление кабины отменено');
    }
  };



  return (
    <>
      <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div class="max-w-sm bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-1">
          <div class="p-5">
            <div className='flex flex-row'>
            <div>
              <h5 class="text-gray-900 text-xl font-medium mb-2">{title}</h5>
              <p class="text-gray-700 text-base mb-4  font-light">{description}</p>
              <div className="border-l  border-slate-400">
                <p class="pl-2 text-gray-700 text-xs font-thin mb-4">{note}</p>
              </div>
            </div>
            <div>
              <img class="rounded-t-lg h-32 pr-4 pt-4" src={imgUrl} alt="нет фото" />
            </div>
            </div>

            <Link
              to={`/EditShower/${cabin_id}`}
              className=" bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded ">
              Редактировать
            </Link>
          <div><p className='font-extralight text-xs pt-2 text-blue-700 cursor-pointer' onClick={handleDeleteShower}>Удалить</p></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowerCard;
