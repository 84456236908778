import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getGlass, selectAllGlass } from '../store/slice/glassSlice';
import Glass from '../components/Glass';
import AddNewGlass from '../components/AddNewGlass';

const GlassPage = () => {
  const dispatch = useDispatch();
  const allGlass = useSelector(selectAllGlass);
  const [showAddNewShower, setShowAddNewShower] = useState(false);



  // Эта функция будет передана в AddNewShower для закрытия компонента
  const handleClose = () => {
    setShowAddNewShower(false);
  };

  return (
    <>
      <Navbar></Navbar>
      <p className="text-center text-xl font-semibold pt-4 pb-4">Типы стекла</p>
      <div className="pt-6">
        <button
          className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowAddNewShower(true)}>
          Добавить стекло
        </button>
        {showAddNewShower && <AddNewGlass onClose={handleClose} />}
      </div>
      {/* Отображаем список стекла, если он доступен */}
      {allGlass && allGlass.length > 0 ? (
        allGlass.map((glassItem, index) => (
          // Предполагается, что у glassItem есть свойства, которые вы хотите отобразить
          <div key={index}>
            <Glass
              propsName={glassItem.name}
              propsPrice={glassItem.price}
              propsId={glassItem.glass_id}
            />
          </div>
        ))
      ) : (
        <p>No glass data available.</p>
      )}
    </>
  );
};

export default GlassPage;
