import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createSelector } from 'reselect';

export const getGlass = createAsyncThunk(
    'glass/getGlass1', // Уникальное имя для действия thunk
    async ({ userId }, { rejectWithValue }) => {
        console.log('🎈getGlass');
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/getglass`, {
                params: {
                  user_id: userId
                }
              });
            return response.data; // Данные будут переданы как `payload` в `extraReducers`
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addNewGlass = createAsyncThunk(
    'showers/addNewGlassSatus',
    async ({ name, price, userId }, { rejectWithValue }) => {
        try {
            console.log('🎈 addNewGlass');
            console.log('name = ', name);
            console.log('price = ', price);
            console.log('userId = ', userId);
            // Отправляем POST-запрос на сервер с новым именем душевой кабины
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addnewglass`, {
                name: name,
                price: price,
                user_id: userId
            });
            return response.data; // Возвращаем данные из ответа сервера 
        } catch (error) {
            // В случае ошибки возвращаем отклоненное значение
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateGlass = createAsyncThunk(
    'glass/updateGlassStatus',
    async ({ name, glass_id, price }, thunkAPI) => {
        console.log('🎈 updateGlass');
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateGlass`, {
                name: name,
                glass_id: glass_id,
                price: price
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);
export const deleteGlass = createAsyncThunk(
    'glass/deleteGlassStatus',
    async ({ glass_id }, thunkAPI) => {
        console.log('🎈 deleteGlass');
        console.log('glass_id =', glass_id);
        try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteGlass`, {
                data: { glass_id: glass_id },
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }
    }
);


export const glassSlice = createSlice({
    name: 'glass',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGlass.fulfilled, (state, action) => {

                state.data = action.payload;
            })
            .addCase(updateGlass.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const index = state.data.findIndex(shower => shower.glass_id === parseInt(action.payload.glass_id, 10));

                if (index !== -1) {
                    state.data[index].name = action.payload.name;
                    state.data[index].price = action.payload.price;
                }
            })
            .addCase(addNewGlass.fulfilled, (state, action) => {
                state.loading = false;
                // Можно добавить новую душевую кабину в состояние, если необходимо
                state.data.push(action.payload);
            })
            .addCase(deleteGlass.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.data.findIndex(shower => shower.glass_id === parseInt(action.payload.glass_id, 10));
                if (index !== -1) {
                    // Создание нового массива без элемента, который нужно удалить
                    state.data = [
                        ...state.data.slice(0, index),
                        ...state.data.slice(index + 1)
                    ];
                }
            })
    },
})

// Селектор для получения данных стекла
export const selectAllGlass = createSelector(
    (state) => state.glass.data, // Селектор, который выбирает часть стейта
    (data) => data // Функция преобразователь, которая возвращает нужные данные
);


export default glassSlice.reducer;
