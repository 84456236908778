//src\store\slices\userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: true,
    email: null,
    token: null,
    id: null, // firebase id
    id_db: null, // user id from database - Users table
  };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIdDb: (state, action) => {
      state.id_db = action.payload;
    },
    setUser: (state, action) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.isLoading = false;
    },
    removeUser: (state) => {
      state.email = null;
      state.token = null;
      state.id = null;
      state.isLoading = false;
    },
  },
});

export const selectUserId = (state) => state.user.id_db; 



export const { setUser, removeUser, setLoading, setIdDb } = userSlice.actions;
export default userSlice.reducer;