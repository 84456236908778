import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewSet } from '../store/slice/showerSlice';

const AddNewSet = ({ onClose, cabin_id }) => {
  const dispatch = useDispatch();
  const [setName, setSetName] = useState('');
  const handleInputChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setSetName(event.target.value);
  };
  function handleAddNewShower() {
    dispatch(addNewSet({ name: setName, cabin_id }));
    onClose();
  }

  return (
    <>
      <div>Новый набор *</div>
      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Название набора"
        value={setName} // Привязываем значение инпута к стейту
        onChange={handleInputChange} // Устанавливаем обработчик изменений
      />
      <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={handleAddNewShower}>
        Сохранить
      </button>
    </>
  );
};

export default AddNewSet;
