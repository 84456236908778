import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createSelector } from 'reselect';

export const deleteDimension = createAsyncThunk(
  'glass/deleteDeleteDimensionStatus',
  async ({ dimension_id }, thunkAPI) => {
    console.log('🎈 deleteDimension');
    console.log('dimension_id =', dimension_id);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteDimension`, {
        data: { dimension_id },
      });
      if (response.status === 200) {
        console.log('response.data = ', response.data);
        return response.data;
      } else {
        throw new Error('Failed to update glass.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const deleteShowerImage = createAsyncThunk(
  'showers/deleteShowerImage',
  async ({ cabin_id, userId }, { rejectWithValue }) => {
    try {
      console.log('🎈 deleteDishImage');
      console.log('cabin_id = ', cabin_id);
      console.log('userId deleteDishImage = ', userId);

      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteShowerImage`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { userId, cabin_id } // передача дополнительных данных в теле DELETE запроса
      });

      console.log('response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const deleteSet = createAsyncThunk(
  'showers/deleteSetStatus',
  async ({ set_id, cabinId }, { rejectWithValue }) => {
    try {
      console.log('🎈 deleteSet');
      console.log('set_id = ', set_id);
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteSet`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { set_id, cabinId } // передача дополнительных данных в теле DELETE запроса
      });

      console.log('response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteShower = createAsyncThunk(
  'showers/deleteShowerStatus',
  async ({ cabin_id }, { rejectWithValue }) => {
    try {
      console.log('🎈 deleteShower');
      console.log('cabin_id = ', cabin_id);
      const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteShower`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { cabin_id } // передача дополнительных данных в теле DELETE запроса
      });
      console.log('response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const uploadDishImageThunk = createAsyncThunk(
  'showers/uploadDishImage',
  async ({ file, userId, cabin_id }, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append('userId', userId); // Добавляем userId перед файлом
    formData.append('cabin_id', cabin_id); // Добавляем categoryId перед файлом
    formData.append('image', file); // Добавляем файл

    console.log(userId);
    console.log(cabin_id);
    console.log(file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/uploadShowerImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response.data = ', response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getShowers = createAsyncThunk(
  'showers/getShowers', // Уникальное имя для действия thunk
  async ({ userId }, { rejectWithValue }) => {
    try {
      console.log('🎈 getShowers');
      console.log('userId >> ', userId);

      // Выполните запрос с использованием axios
      const response = await axios.get(`${process.env.REACT_APP_URL}/api/getshowers`, {
        params: {
          user_id: userId
        }
      });
      return response.data; // Данные будут переданы как `payload` в `extraReducers`
    } catch (error) {
      // В случае ошибки, передайте её в `rejectWithValue` для обработки в `extraReducers`
      return rejectWithValue(error.response.data);
    }
  }
);

export const addNewShower = createAsyncThunk(
  'showers/addNewShower',
  async ({ showerName, user_id }, { rejectWithValue }) => {
    try {
      console.log('🎈 addNewShower');
      // Отправляем POST-запрос на сервер с новым именем душевой кабины
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addnewshower`, {
        name: showerName,
        user_id: user_id
      });
      return response.data; // Возвращаем данные из ответа сервера
    } catch (error) {
      // В случае ошибки возвращаем отклоненное значение
      return rejectWithValue(error.response.data);
    }
  }
);

//UPDATES SHOWERS
export const updateTitle = createAsyncThunk(
  'showers/updateTitleStatus',
  async ({ title, cabin_id }, thunkAPI) => {
    console.log('🎈 updateTitle');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateTitle`, {
        name: title,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateNote = createAsyncThunk(
  'showers/updateNoteStatus',
  async ({ note, cabin_id }, thunkAPI) => {
    console.log('🎈 updateNote');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateNote`, {
        note: note,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update branch name.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateDescription = createAsyncThunk(
  'showers/updateDescriptionStatus',
  async ({ description, cabin_id }, thunkAPI) => {
    console.log('🎈 updateDescription');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateDescription`, {
        description: description,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateHeight = createAsyncThunk(
  'showers/updateHeightStatus',
  async ({ height, cabin_id }, thunkAPI) => {
    console.log('🎈 updateHeight');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateHeight`, {
        height: height,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updatePosition = createAsyncThunk(
  'showers/updatePositionStatus',
  async ({ position, cabin_id }, thunkAPI) => {
    console.log('🎈 updatePosition');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updatePosition`, {
        position: position,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const updateMargin = createAsyncThunk(
  'showers/updateMarginDigitalStatus',
  async ({ margindigit, marginper, cabin_id }, thunkAPI) => {
    console.log('🎈 updateMarginDigit');
    console.log('margindigit = ', margindigit);
    console.log('marginper = ', marginper);
    console.log('cabin_id = ', cabin_id);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateMargin`, {
        margindigit,
        marginper,
        cabin_id: cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);




export const updateDimension = createAsyncThunk(
  'showers/updateDimensionStatus',
  async ({ title, dimension_id, dimension }, thunkAPI) => {
    console.log('🎈 updateDimension');
    console.log('title = ', title);
    console.log('dimension_id = ', dimension_id);
    console.log('dimension = ', dimension);

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateDimension`, {
        dimension,
        dimension_id,
        title
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const toggleGlassType = createAsyncThunk(
  'showers/updateGlassToggleStatus',
  async ({ glassItem, isCheckboxChecked, cabinId, name }, thunkAPI) => {
    console.log('🎈 updateHeight');
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateGlassToggle`, {
        glassItem: glassItem,
        isCheckboxChecked: isCheckboxChecked,
        cabinId: cabinId,
        name: name

      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const addNewSet = createAsyncThunk(
  'showers/updateAddNewSetStatus',
  async ({ cabin_id, name }, thunkAPI) => {
    console.log('🎈 updateHeight');
    console.log('cabin_id = ', cabin_id);
    console.log('name = ', name);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewSet`, {
        cabin_id, name

      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const renameSet = createAsyncThunk(
  'showers/renameSet',
  async ({ setId, name, cabin_id }, thunkAPI) => {
    console.log('setId = ', setId);
    console.log('name = ', name);
    console.log('cabin_id = ', cabin_id);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/renameSetName`, {
        setId, name, cabin_id
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const addNewDimension = createAsyncThunk(
  'showers/updateAddDimensionSetStatus',
  async ({ shower_cabin_id, title, dimension }, thunkAPI) => {
    console.log('🎈 addNewDimension');
    console.log('shower_cabin_id = ', shower_cabin_id);
    console.log('title = ', title);
    console.log('dimension = ', dimension);
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewDimension`, {
        shower_cabin_id, title, dimension

      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const updateQuantity = createAsyncThunk(
  'showers/updateQuantityStatus',
  async ({ hardwareId, set_id, quantity, incdec }, thunkAPI) => {
    console.log('🎈 updateQuantity');
    console.log('hardwareId = ', hardwareId);
    console.log('set_id = ', set_id);
    console.log('quantity = ', quantity);
    console.log('incdec = ', incdec);

    try {
      let response;
      // Если количество равно 0, отправляем запрос на удаление
      if (quantity === 0) {
        response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteQuantity`, {
          params: { hardwareId, set_id }, // Передаем данные в теле запроса DELETE
        });
      } else {
        // Иначе отправляем запрос на обновление количества
        response = await axios.post(`${process.env.REACT_APP_URL}/api/updateQuantity`, {
          hardwareId, set_id, quantity, incdec
        });
      }

      if (response.status === 200) {
        // Возвращаем обновленное состояние, включая индикатор удаления
        return { ...response.data, hardwareId, set_id, quantity, incdec, deleted: quantity === 0 };
      } else {
        throw new Error('Failed to update quantity.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addHardwareToSet = createAsyncThunk(
  'showers/addHardwareToSetStatus',
  async ({ set_id, hardware_id }, thunkAPI) => {
    console.log('🎈 addHardwareToSet');
    /*     console.log('set_id = ', set_id);
        console.log('hardware_id = ', hardware_id); */
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/api/addHardwareToSet`, {
        set_id, hardware_id

      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Failed to update description.');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Создание async thunk для загрузки фотографии
export const uploadPhoto = createAsyncThunk(
  'showers/uploadPhoto',
  async ({ file, glassId, hardwareSetId }, { rejectWithValue }) => {
    console.log('🎈 вызван uploadPhoto');
    console.log('glassId = ', glassId);
    console.log('hardwareSetId = ', hardwareSetId);
    try {
      const formData = new FormData();
      formData.append('glass_id', glassId);
      formData.append('hardware_set_id', hardwareSetId);
      formData.append('file', file);

      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_URL}/api/upload-photo`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
/* export const uploadPhoto = createAsyncThunk(
  'showers/uploadPhoto',
  async ({ file, glassId, hardwareSetId }, { rejectWithValue }) => {
    console.log('🎈 вызван uploadPhoto');
    console.log('glassId = ', glassId);
    console.log('hardwareSetId = ', hardwareSetId);
    try {
      const formData = new FormData();
      formData.append('glass_id', glassId);
      formData.append('hardware_set_id', hardwareSetId);
      formData.append('file', file);

      const response = await fetch(`${process.env.REACT_APP_URL}/api/upload-photo`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Network response was not ok');
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
); */


export const deletePhoto = createAsyncThunk(
  'showers/deletePhoto',
  async (photoFileName, { rejectWithValue }) => {
    console.log('photoFileName = ', photoFileName);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/delete-photo`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ photoFileName }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при удалении фото.');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getAllPhotos = createAsyncThunk(
  'showers/getAllPhotos',
  async (userId, { rejectWithValue }) => {
    console.log('getAllPhotos userId = ', userId);
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/photos/${userId}`);
      if (!response.ok) {
        throw new Error('Не удалось загрузить фотографии.');
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const showersSlice = createSlice({
  name: 'showers',
  initialState: {
    data: [],
    loading: false,
    error: null,
    glass: [],
    photos: {},
    //photos{} 
    //1-е число это set_id из таблицы hardware_sets
    //2-число это glass_id из таблицы glass 
  },
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
    },
    fetchDataSuccess: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    fetchDataFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(toggleGlassType.fulfilled, (state, action) => {
        console.log('action.payload = ', action.payload);
        const { glass_id, cabin_id, isCheckboxChecked } = action.payload;

        const cabinIndex = state.data.findIndex(cabin => cabin.cabin_id === parseInt(cabin_id));
        console.log('action.payload = ', action.payload);

        console.log('cabin_id ===', cabin_id);
        console.log('cabinIndex = ', cabinIndex);
        console.log('glass_id = ', glass_id);
        console.log('isCheckboxChecked = ', isCheckboxChecked);

        if (cabinIndex !== -1) {
          if (isCheckboxChecked) {
            console.log('Сработал + добавление');
            state.data[cabinIndex].glass_types.push(action.payload);
          } else {
            console.log('Сработал - удаление');
            state.data[cabinIndex].glass_types = state.data[cabinIndex].glass_types.filter(glass => glass.glass_id !== parseInt(glass_id));
          }
        }
      })
      .addCase(uploadDishImageThunk.fulfilled, (state, action) => {
        // Найти и обновить блюдо в состоянии
        console.log('action.payload = ', action.payload);
        const showerIndex = state.data.findIndex(showers => showers.cabin_id === Number(action.payload.cabin_id));
        console.log('dishIndex = ', showerIndex);
        if (showerIndex !== -1) {
          state.data[showerIndex].img_url = action.payload.img_url; // или какое-либо другое значение, указывающее на отсутствие изображения
        }
      })
      .addCase(deleteShower.fulfilled, (state, action) => {
        const { cabin_id } = action.payload;
        // Фильтруем массив, чтобы удалить кабину с cabin_id
        state.data = state.data.filter(shower => shower.cabin_id !== cabin_id);
      })

      .addCase(deleteDimension.fulfilled, (state, action) => {
        const { dimension_id } = action.payload;
        console.log('📍action.payload = ', action.payload);

        // Находим душевую кабину, которой принадлежит удаляемое измерение
        const showerIndex = state.data.findIndex(shower =>
          shower.dimensions.some(dimension => dimension.dimension_id === dimension_id)
        );

        if (showerIndex !== -1) {
          // Удаляем измерение из найденной душевой кабины
          state.data[showerIndex].dimensions = state.data[showerIndex].dimensions.filter(
            dimension => dimension.dimension_id !== dimension_id
          );
        }

        // Возможно, вам также понадобится обновить какие-то UI элементы или состояние загрузки
        state.loading = false;
      })
      .addCase(updateHeight.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateHeight.fulfilled, (state, action) => {
        state.status = 'succeeded';

        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);
        if (index !== -1) {
          state.data[index].height = action.payload.height;
        }
      })
      .addCase(updatePosition.fulfilled, (state, action) => {
        state.status = 'succeeded';

        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);
        if (index !== -1) {
          state.data[index].position = action.payload.position;
        }
      })
      .addCase(addNewSet.fulfilled, (state, action) => {
        const { cabin_id, name, set_id } = action.payload.set;

        // Найти соответствующую душевую кабину по cabin_id
        const shower = state.data.find(shower => shower.cabin_id === cabin_id);

        if (shower) {
          // Добавляем новый набор в массив hardware_sets душевой кабины
          shower.hardware_sets.push({
            set_id: set_id,
            name: name,
            hardwares: [] // Новый набор пока не содержит фурнитуры
          });
        } else {
          // Если кабины нет в состоянии, это может быть ошибка или необходимо обновить данные из API
          console.error('Shower cabin not found in state:', cabin_id);
          // Здесь может быть код для обработки такой ситуации, например запрос на обновление данных
        }
      })
      .addCase(addNewDimension.fulfilled, (state, action) => {
        const { shower_cabin_id, title, dimension, dimension_id } = action.payload;

        // Найти соответствующую душевую кабину по cabin_id
        const shower = state.data.find(shower => shower.cabin_id === shower_cabin_id);

        if (shower) {
          // Добавляем новый набор в массив hardware_sets душевой кабины
          shower.dimensions.push({
            dimension_id: dimension_id,
            title: title,
            dimension: dimension,
            shower_cabin_id: shower_cabin_id

          });
        } else {
          // Если кабины нет в состоянии, это может быть ошибка или необходимо обновить данные из API
          console.error('Shower cabin not found in state:', shower_cabin_id);
          // Здесь может быть код для обработки такой ситуации, например запрос на обновление данных
        }
      })

      .addCase(updateHeight.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateNote.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateNote.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('action.payload = ', action.payload);
        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);
        if (index !== -1) {
          state.data[index].note = action.payload.note;
        }
      })
      .addCase(deleteSet.fulfilled, (state, action) => {
        const { set_id, cabinId } = action.payload;
        console.log('📍action.payload = ', action.payload);

        // Находим душевую кабину по cabinId
        const showerIndex = state.data.findIndex(shower => shower.cabin_id === cabinId);
        if (showerIndex !== -1) {
          // Находим и удаляем набор из hardware_sets
          state.data[showerIndex].hardware_sets = state.data[showerIndex].hardware_sets.filter(set => set.set_id !== set_id);
        }
      })

      .addCase(addHardwareToSet.fulfilled, (state, action) => {
        const { hardware_id, set_id, quantity, name, price } = action.payload;

        console.log('😎 action.payload = ', action.payload);

        // Найти соответствующую душевую кабину и набор фурнитуры
        const showerIndex = state.data.findIndex(shower => shower.hardware_sets.some(set => set.set_id === set_id));
        if (showerIndex !== -1) {
          // Найти набор фурнитуры внутри душевой кабины
          const hardwareSetIndex = state.data[showerIndex].hardware_sets.findIndex(set => set.set_id === set_id);
          if (hardwareSetIndex !== -1) {
            // Инициализируем hardwares как пустой массив, если он еще не был инициализирован
            state.data[showerIndex].hardware_sets[hardwareSetIndex].hardwares = state.data[showerIndex].hardware_sets[hardwareSetIndex].hardwares || [];
            // Далее, ваш код без изменений...
            const existingHardwareIndex = state.data[showerIndex].hardware_sets[hardwareSetIndex].hardwares.findIndex(hw => hw.hardware_id === hardware_id);
            if (existingHardwareIndex !== -1) {
              state.data[showerIndex].hardware_sets[hardwareSetIndex].hardwares[existingHardwareIndex].quantity += 1;
            } else {
              state.data[showerIndex].hardware_sets[hardwareSetIndex].hardwares.push({
                hardware_id,
                name,
                price,
                quantity,
              });
            }
          }
        }
      })
      .addCase(updateQuantity.fulfilled, (state, action) => {
        // Извлекаем данные из action.payload
        const { hardwareId, set_id, quantity, del } = action.payload;

        // Найдем душевую кабину, которая содержит набор фурнитуры с set_id
        const shower = state.data.find(shower => shower.hardware_sets.some(set => set.set_id === set_id));

        if (shower) {
          // Найдем набор фурнитуры внутри душевой кабины
          const hardwareSet = shower.hardware_sets.find(set => set.set_id === set_id);
          if (hardwareSet && hardwareSet.hardwares) {
            // Найдем конкретную фурнитуру внутри набора по hardwareId
            const hardwareItem = hardwareSet.hardwares.find(hw => hw.hardware_id === hardwareId);
            if (hardwareItem) {
              if (del) {
                // Находим душевую кабину
                const shower = state.data.find(shower => shower.hardware_sets.some(set => set.set_id === set_id));
                if (shower) {
                  // Находим набор фурнитуры
                  const hardwareSet = shower.hardware_sets.find(set => set.set_id === set_id);
                  if (hardwareSet) {
                    // Удаляем элемент фурнитуры из набора
                    hardwareSet.hardwares = hardwareSet.hardwares.filter(hw => hw.hardware_id !== hardwareId);
                  }
                }
              } else {
                // Обновим количество этой фурнитуры
                hardwareItem.quantity = quantity;
              }
            }
          }
        }
      })
      .addCase(updateDimension.fulfilled, (state, action) => {
        const { dimension_id, title, dimension } = action.payload;

        // Находим душевую кабину, которой принадлежит изменяемый размер
        const showerToUpdate = state.data.find(shower =>
          shower.dimensions.some(d => d.dimension_id === dimension_id)
        );

        if (showerToUpdate) {
          // Находим и обновляем размер в массиве dimensions
          const dimensionToUpdate = showerToUpdate.dimensions.find(d => d.dimension_id === dimension_id);
          if (dimensionToUpdate) {
            dimensionToUpdate.title = title;
            dimensionToUpdate.dimension = parseFloat(dimension);
          }
        }
      })
      .addCase(renameSet.fulfilled, (state, action) => {
        const { cabin_id, setId, name } = action.payload;

        // Находим душевую кабину по cabin_id
        const cabin = state.data.find(cabin => cabin.cabin_id === parseInt(cabin_id));
        console.log('~ cabin = ', cabin);
        if (cabin) {
          // Находим в этой кабине набор фурнитуры по setId
          const hardwareSet = cabin.hardware_sets.find(set => set.set_id === setId);
  
          if (hardwareSet) {
              // Устанавливаем новое имя для набора фурнитуры
              hardwareSet.name = name;
          }
      }
        /*
        if (showerToUpdate) {
          // Находим и обновляем размер в массиве dimensions
          const dimensionToUpdate = showerToUpdate.dimensions.find(d => d.dimension_id === dimension_id);
          if (dimensionToUpdate) {
            dimensionToUpdate.title = title;
            dimensionToUpdate.dimension = parseFloat(dimension);
          }
        } */
      })
      .addCase(updateNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateTitle.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTitle.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('action.payload = ', action.payload);
        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);

        if (index !== -1) {
          state.data[index].name = action.payload.name;
        }
      })
      .addCase(updateMargin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log('🖥️action.payload = ', action.payload);
        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);

        if (index !== -1) {
          state.data[index].marginper = parseFloat(action.payload.marginper);
          state.data[index].margindigit = parseFloat(action.payload.margindigit);
        }
      })
      .addCase(updateTitle.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(updateDescription.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDescription.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('action.payload = ', action.payload);
        const index = state.data.findIndex(shower => shower.cabin_id === parseInt(action.payload.cabin_id, 10));
        console.log('index = ', index);

        if (index !== -1) {
          state.data[index].description = action.payload.description;
        }

      })
      .addCase(updateDescription.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.error;
      })
      .addCase(getShowers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getShowers.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.map(shower => ({
          ...shower,
          dimensions: shower.dimensions || []
        }));
      })
      .addCase(getShowers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }).addCase(addNewShower.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewShower.fulfilled, (state, action) => {
        const newShower = {
          ...action.payload, // Содержимое новой душевой кабины
          hardware_sets: [],  // Пустой массив наборов фурнитуры
          dimensions: [],
          glass_types: [],
        };

        state.data.push(newShower); // Добавляем новую кабину в массив
      })
      .addCase(addNewShower.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadPhoto.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        // Остановка индикатора загрузки
        state.loading = false;
        console.log('🎟 action.payload = ', action.payload);

        // Деструктуризация данных из action.payload
        const { photo, glassId, hardwareSetId } = action.payload;

        // Инициализация объекта для этого hardwareSetId, если он еще не существует
        if (!state.photos[hardwareSetId]) {
          state.photos[hardwareSetId] = {};
        }

        // Инициализация массива для этого glassId, если он еще не существует
        if (!state.photos[hardwareSetId][glassId]) {
          state.photos[hardwareSetId][glassId] = [];
        }

        // Добавление фото в соответствующий массив
        state.photos[hardwareSetId][glassId].push(photo);
      })
      .addCase(uploadPhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {

        console.log('action.payload = ', action.payload);
        // Удалите фото из состояния на основе полученного ответа
        const { photoId, hardwareSetId, glassId } = action.payload;
        // Удаляем фото из состояния
        if (state.photos[hardwareSetId] && state.photos[hardwareSetId][glassId]) {
          state.photos[hardwareSetId][glassId] = state.photos[hardwareSetId][glassId].filter(photo => photo.photo_id !== photoId);
        }
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        // Обработка ошибки удаления фото
        // ...
      })
      .addCase(getAllPhotos.fulfilled, (state, action) => {
        // Очищаем текущее состояние photos
        state.photos = {};

        // Преобразуем полученный массив фотографий в структуру state.photos
        action.payload.forEach(photo => {
          const { set_id, glass_id } = photo;

          // Инициализируем объект для set_id, если он ещё не создан
          if (!state.photos[set_id]) {
            state.photos[set_id] = {};
          }

          // Инициализируем массив для glass_id, если он ещё не создан
          if (!state.photos[set_id][glass_id]) {
            state.photos[set_id][glass_id] = [];
          }

          // Добавляем фотографию в массив
          state.photos[set_id][glass_id].push({
            photo_id: photo.photo_id,
            glass_hardware_set_id: photo.glass_hardware_set_id,
            photo_url: photo.photo_url
          });
        });
      })

      .addCase(getAllPhotos.rejected, (state, action) => {
        // Обработка ошибки загрузки фотографий
        state.error = action.payload;
      });;
    ;;
  },
});

export const selectGlassTypesByCabinId = (state, cabinId) => {
  const shower = state.showers.data.find(shower => shower.cabin_id === Number(cabinId));
  return shower ? shower.glass_types : [];
};

export const selectShowersData = state => state.showers.data;

export const selectShowerById = (state, cabinId) => {
  return state.showers.data?.find(shower => shower.cabin_id === Number(cabinId));
};

// Селектор, который извлекает все наборы фурнитуры для конкретной душевой кабины по cabinId
export const selectHardwareSetsByCabinId = (state, cabinId) => {
  // Находим душевую кабину по cabinId
  const shower = state.showers.data.find(shower => shower.cabin_id === Number(cabinId));
  // Возвращаем наборы фурнитуры или пустой массив, если ничего не найдено
  return shower ? shower.hardware_sets : [];
};

// Селектор для получения деталей набора фурнитуры по set_id
export const selectHardwareSetDetails = (state, setId) => {
  console.log('сработал селектор');
  const hardwareSet = state.showers.data.flatMap(shower => shower.hardware_sets)
    .find(set => set.set_id === setId);
  return hardwareSet ? hardwareSet.hardwares : [];
};


export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = showersSlice.actions;

export default showersSlice.reducer;
