import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/slice/userSlice';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        user.getIdToken().then((token) => {
          dispatch(
            setUser({
              email: user.email,
              id: user.uid,
              token: token,
            }),
          );
          navigate('/'); //переадресация на главную страницу
        });
      })
      .catch(() => alert('Неправильный логин или пароль'));
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(({ user }) => {
        user.getIdToken().then((token) => {
          dispatch(
            setUser({
              email: user.email,
              id: user.uid,
              token: token,
            }),
          );
          navigate('/'); //переадресация на главную страницу
        });
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded shadow-lg p-8 m-4">
        <h1 className="block w-full text-center text-grey-darkest mb-6">Войти</h1>
        <form className="mb-4" onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
              Пароль
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit">
              Войти
            </button>
          </div>
        </form>
        <div className="text-center">
          <button
            className="mt-4 bg-white text-gray-800 rounded shadow w-full py-2 px-4 text-sm hover:bg-gray-100 focus:outline-none"
            onClick={handleGoogleLogin}>
            <img src="/icons/google.png" alt="Google" className="h-6 w-6 inline mr-2" />
            Войти через Google
          </button>

          <div className="text-center mt-4">
            <span>Нет учетной записи? </span>
            <button
              className="text-blue-500 hover:text-blue-700 font-semibold"
              onClick={() => navigate('/register')}>
              Зарегистрироваться
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Login };
