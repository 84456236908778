import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addHardwareToSet,
  deleteDishImage,
  deleteLogo,
  deleteSet,
  deleteShowerImage,
  selectGlassTypesByCabinId,
  selectHardwareSetDetails,
  selectHardwareSetsByCabinId,
  selectShowerById,
  selectShowersData,
  toggleGlassType,
  updateDescription,
  updateDimension,
  updateHeight,
  updateMargin,
  updateMarginDigit,
  updateMarginDigital,
  updateMarginPer,
  updateNote,
  updatePosition,
  updateTitle,
  uploadDishImageThunk,
} from '../store/slice/showerSlice';
import Navbar from '../components/Navbar';
import { RiDeleteBinLine } from 'react-icons/ri';
import Resizer from 'react-image-file-resizer';
import { selectUserId } from '../store/slice/userSlice';
import { BiImageAdd } from 'react-icons/bi';
import { selectAllGlass } from '../store/slice/glassSlice';
import { DropdownDimension } from '../components/DropdownDimension';
import HardwareTable from '../components/HardwareTable';
import AddNewSet from '../components/AddNewSet';
import Dimension from '../components/Dimension';
import AddNewDimension from '../components/AddNewDimension';
import { BiEdit } from 'react-icons/bi';
import RenameSet from '../components/RenameSet';

const EditShowerPage = ({ cabin_id }) => {
  const dispatch = useDispatch();

  const allGlass = useSelector(selectAllGlass);

  const params = useParams();
  const cabinId = params.cabin_id;
  const shower = useSelector((state) => selectShowerById(state, cabinId));

  const dimensions = shower.dimensions;

  console.log('🚿 shower >>>>> ', shower);

  const hardwareSets = useSelector((state) => selectHardwareSetsByCabinId(state, cabinId));
  console.log('hardwareSets = ', hardwareSets);
  const [showAddNewSet, setShowAddNewSet] = useState(false);
  const [showRenameSet, setShowRenameSet] = useState(false);

  const handleClose = () => {
    setShowAddNewSet(false);
    setShowRenameSet(false);
  };
  console.log('cabinId = ', cabinId);

  const glassTypes = useSelector((state) => selectGlassTypesByCabinId(state, cabinId));
  console.log('glassTypes = ', glassTypes);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [height, setHeight] = useState('');
  const [marginper, setMarginPer] = useState('');
  const [margindigit, setMarginDigit] = useState('');
  const [position, setPosition] = useState('');

  const [initialName, setInitialName] = useState(shower ? shower.name : null);

  const [initialMarginPer, setInitialMarginPer] = useState(shower ? shower.marginper : null);
  const [initialPosition, setInitialPosition] = useState(shower ? shower.position : null);
  const [initialMarginDigit, setInitialMarginDagit] = useState(shower ? shower.margindigit : null);

  const [initialNote, setInitialNote] = useState(shower ? shower.note : null);
  const [initialDescription, setInitialDescription] = useState(shower ? shower.description : null);
  const [initialHeight, setInitialHeight] = useState(shower ? shower.height : -1);
  
  // Состояние для хранения выбранного set_id
  const [selectedSetId, setSelectedSetId] = useState(null);
  const [addId, setAddId] = useState(null);
  const [showAddNewDimension, setShowAddNewDimension] = useState(false);

  // Получаем детали выбранного набора фурнитуры
  const selectedHardwareDetails = useSelector((state) =>
    selectHardwareSetDetails(state, selectedSetId),
  );
  // Обработчик изменения выбора в select
  const handleSelectChange = (e) => {
    console.log('~ Изменили набор фурнитуры ');
    setSelectedSetId(Number(e.target.value));
  };

  //console.log('initialDescription = ', initialDescription);

  const [img_url, setImg_url] = useState(shower ? shower.img_url : null);

  useEffect(() => {
    if (hardwareSets && hardwareSets.length > 0) {
      //setSelectedSetId(hardwareSets[0].set_id);
    }
  }, [hardwareSets]);

  useEffect(() => {
    if (hardwareSets && hardwareSets.length > 0) {
      setSelectedSetId(hardwareSets[0].set_id);
    }
  }, []);

  useEffect(() => {
    if (shower) {
      setTitle(shower.name);
      setDescription(shower.description);
      setNote(shower.note);
      setHeight(shower.height);
      setImg_url(shower.img_url);
      setMarginDigit(shower.margindigit);
      setPosition(shower.position);

      setMarginPer(shower.marginper);
    }
  }, [shower]);

  const [selectedOption, setSelectedOption] = useState(shower.dimension);

  const handleSelect = (value) => {
    setSelectedOption(value); // обновляем состояние родительского компонента новым значением
    dispatch(updateDimension({ cabin_id: cabinId, dimension: value }));
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };
  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };
  const handleMarginPerChange = (e) => {
    setMarginPer(e.target.value);
  };
  const handleMarginDigitalChange = (e) => {
    setMarginDigit(e.target.value);
  };
  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const changeTitleOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (title !== initialName) {
      dispatch(updateTitle({ title: title, cabin_id: cabinId }));
      setInitialName(title);
    }
  };
  const changeHeightOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (height !== initialHeight) {
      dispatch(updateHeight({ height: height, cabin_id: cabinId }));
      setInitialHeight(height);
    }
  };
  const changePositionOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (position !== initialPosition) {
      dispatch(updatePosition({ position: position, cabin_id: cabinId }));
      setInitialPosition(position);
    }
  };

  const changeMarginOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (marginper !== initialMarginPer) {
      const effectiveMarginPer = marginper || 0;
      dispatch(updateMargin({ marginper: effectiveMarginPer, margindigit, cabin_id: cabinId }));
      setInitialMarginPer(marginper);
      return;
    }
    if (margindigit !== initialMarginDigit) {
      const effectiveMarginDigit = margindigit || 0;
      dispatch(updateMargin({ marginper, margindigit: effectiveMarginDigit, cabin_id: cabinId }));
      setInitialMarginPer(marginper);
      return;
    }
  };

  const changeNoteOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (note !== initialNote) {
      //dispatch(updateNote({ note: note, cabin_id: cabinId }));
      setInitialName(title);
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const changeDescriptionOnServer = (e) => {
    console.log('cabin_id = ', cabin_id);
    if (description !== initialDescription) {
      dispatch(updateDescription({ cabin_id: cabinId, description: description }));
      // Обновляем начальное значение, так как текст был сохранен
      setInitialDescription(description);
    }
  };

  const fileInput = useRef(null);

  const userId = useSelector(selectUserId);

  function handleDeleteImage() {
    //console.log('🛎️ handleDeleteImage');
    dispatch(deleteShowerImage({ userId, cabin_id: cabinId }));
  }

  function handleUploadShowerImage() {
    //console.log('Вызван uploadDishImage function');
    const file = fileInput.current.files[0];
    if (file) {
      dispatch(uploadDishImageThunk({ file, userId, cabin_id: cabinId }));
    }
  }

  const handleAddIdChange = (event) => {
    setAddId(event.target.value);
  };

  const handleOnChange = (glassItem, event) => {
    console.log('handleOnChange сработал');
    console.log('glassItem = ', glassItem);
    const isCheckboxChecked = event.target.checked; // Состояние чек-бокса true/false
    // true - был выключен и включили, false - был включен, и выключили

    dispatch(toggleGlassType({ glassItem, isCheckboxChecked, cabinId, name: glassItem.name }));

    // Диспатчим экшн с новым состоянием чек-бокса и информацией о стекле

    // Также здесь можно будет добавить логику для обновления состояния в БД
  };
  // Функция для проверки, отмечен ли чек-бокс
  const isChecked = (glassItemId) => {
    // Если glass_types пустой или не определен, вернуть false
    if (!glassTypes || glassTypes.length === 0) return false;

    // Проверяем, содержится ли glassItem.id в массиве glass_types
    const exist = glassTypes.some((type) => type?.glass_id === glassItemId);
    return exist;
  };

  const handleAddIdToServer = () => {
    // добавляем фурнитуру в набор

    if (!selectedSetId) {
      alert('Выберите набор фурнитуры');
      return;
    }
    if (!addId) {
      alert('Введите № фурнитуры');
      return;
    }
    dispatch(addHardwareToSet({ set_id: selectedSetId, hardware_id: addId }));
  };

  const handleDeleteSet = () => {
    if (window.confirm('Вы уверены, что хотите удалить этот набор фурнитуры?')) {
      dispatch(deleteSet({ set_id: selectedSetId, cabinId }));
    }
  };

  const handleCloseDimension = () => {
    setShowAddNewDimension(false);
  };

  const addNewPhoto = () => {
    console.log('Добавление фото');
    console.log('selectedSetId = ', selectedSetId);
  };

 

  return (
    <>
      <Navbar></Navbar>
      <div className="ml-6 mt-6">
        <p className="text-center font-semibold text-xl">Редактируем кабину</p>
        <div className="mt-4">
          <button
            className="bg-slate-300 text-slate-800 active:bg-slate-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={addNewPhoto}>
            Добавить фото образцов
          </button>
        </div>

        <div></div>
        <p className="mt-4">Наименование:</p>
        <div className=" mb-4">
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={changeTitleOnServer}
            className="p-1 text-lg w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md  focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div>
          <p className="mt-4">Описание:</p>
          <textarea
            value={description}
            placeholder="Описание душевой кабины"
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            rows="4"
            onChange={handleDescriptionChange}
            onBlur={changeDescriptionOnServer}
          />
        </div>
        <div>
          <p className="mt-4">Заметка, видна только вам:</p>
          <textarea
            value={note}
            placeholder="Заметка к душевой кабине. Видна только администратору"
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            rows="4"
            onChange={handleNoteChange}
            onBlur={changeNoteOnServer}
          />
        </div>
        <div className="mt-4 mb-4">
          <p className="mt-4">Стандартная высота:</p>
          <input
            type="text"
            value={height}
            onChange={handleHeightChange}
            onBlur={changeHeightOnServer}
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className="mt-4 mb-4">
          <p className="mt-4">Наценка %:</p>
          <input
            type="number"
            value={marginper}
            onChange={handleMarginPerChange}
            onBlur={changeMarginOnServer}
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className="mt-4 mb-4">
          <p className="mt-4">Наценка сумма:</p>
          <input
            type="number"
            value={margindigit}
            onChange={handleMarginDigitalChange}
            onBlur={changeMarginOnServer}
            className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className="mt-4 mb-4">
          <p className="mt-4">Порядок сортировки:</p>
          <input
            type="number"
            value={position}
            onChange={handlePositionChange}
            onBlur={changePositionOnServer}
            className="w-16   pl-2  block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div>
          <p className="text-center font-semibold">Стороны кабины</p>
          <div className="pt-6">
            <button
              className="bg-slate-300 text-slate-800 active:bg-slate-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowAddNewDimension(true)}>
              Добавить сторону
            </button>
            {showAddNewDimension && (
              <AddNewDimension onClose={handleCloseDimension} cabinId={cabinId} />
            )}
          </div>

          {dimensions && dimensions.length > 0 ? (
            [...dimensions]
              .sort((a, b) => a.dimension_id - b.dimension_id)
              .map((item, index) => (
                // Теперь dimension отсортированы по dimension_id
                <div key={item.dimension_id}>
                  <Dimension
                    propsTitle={item.title}
                    propsId={item.dimension_id}
                    propsDimension={item.dimension}
                  />
                </div>
              ))
          ) : (
            <p className="text-red-600 font-semibold">В изделии нет ни одной стороны</p>
          )}
        </div>

        <p className="mt-4 font-semibold text-center">Картинка</p>
        <>
          {img_url ? (
            <div className="flex flex-row">
              <img className="h-32 mt-2" src={`${process.env.REACT_APP_URL}/${img_url}`} alt="" />
              <button
                className="mt-2 mr-2 text-white rounded-full w-6 h-6 flex items-center justify-center"
                onClick={handleDeleteImage}>
                <RiDeleteBinLine className="text-slate-700 mt-6" />
              </button>
            </div>
          ) : (
            <div className="">
              <input
                type="file"
                ref={fileInput}
                className="hidden"
                onChange={handleUploadShowerImage}
              />
              <button
                className="flex items-center border-2 p-2"
                onClick={() => fileInput.current.click()}>
                <BiImageAdd className="mr-2" />
                Загрузить фото
              </button>
            </div>
          )}

          <div>
            <p className="pt-6 text-center font-medium text-base pb-2">Используемое стекло</p>
          </div>
          <div className="">
            {allGlass.map((glassItem) => (
              <label key={glassItem?.glass_id}>
                <input
                  className="ml-4"
                  type="checkbox"
                  value={glassItem?.name}
                  checked={isChecked(glassItem?.glass_id)}
                  // Добавьте onChange, если нужно обрабатывать изменения
                  onChange={(event) => handleOnChange(glassItem, event)}
                />
                <span className="pl-1">{glassItem?.name}</span>
              </label>
            ))}
          </div>

          <div className="mt-4">
            <p className="pt- text-center font-medium text-base">Наборы фурнитуры</p>
          </div>

          <button
            className="bg-slate-300 text-slate-800 active:bg-slate-600 font-semibold text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowAddNewSet(true)}>
            Добавить новый набор
          </button>
          {showAddNewSet && <AddNewSet onClose={handleClose} cabin_id={cabinId} />}
          <div className='flex flex-row items-center'>
            <div>
              <select
                className="border-2 text-lg rounded-md	p-2 bg-slate-200"
                value={selectedSetId || ''}
                onChange={handleSelectChange}>
                {hardwareSets.map((set) => (
                  <option key={set.set_id} value={set.set_id}>
                    {set.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='pl-2'>
              <button 
              type='button'
              onClick={() => setShowRenameSet(true)}
              >
                <BiEdit  className='text-slate-800'/>
              </button>
            </div>
            <div>
            {showRenameSet && <RenameSet onClose={handleClose} cabin_id={cabinId} selectedSetId={selectedSetId} />}

            </div>
          </div>
          <div className="flex flex-row mt-4">
            <div className="mr-2">
              <p>№</p>
            </div>
            <input
              type="number"
              onChange={handleAddIdChange}
              className=" font-semibold w-24   px-2 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
            <button
              className="ml-2 bg-slate-300 text-slate-800 active:bg-slate-400  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleAddIdToServer}>
              Добавить в набор
            </button>
          </div>
          <div>
            {selectedHardwareDetails && selectedHardwareDetails.length > 0 ? (
              // В вашем родительском компоненте
              <HardwareTable hardwareDetails={selectedHardwareDetails} setId={selectedSetId} />
            ) : (
              <p>Выберите набор фурнитуры для отображения содержимого.</p>
            )}
          </div>

          <button
            className="ml-2 bg-slate-100 text-red-700 active:bg-slate-400  text-xs px-1 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={handleDeleteSet}>
            Удалить этот набор{' '}
          </button>
        </>
      </div>
    </>
  );
};

export default EditShowerPage;
