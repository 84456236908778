import React, { useState } from 'react';
import { deleteHardwareTag, updateHardwareTag } from '../store/slice/hardwareTagsSlice';
import { useDispatch } from 'react-redux';

const Tags = ({ propsName, propsId }) => {
    const dispatch = useDispatch();
  const [initialName, setInitialName] = useState(propsName);
  const [name, setName] = useState(initialName);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const changeTagOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (name !== initialName) {
      dispatch(updateHardwareTag({ tag_id: propsId, name: name  }));
      setInitialName(name);
      return;
    }    
  };

  const handleDeleteGlass = (id) => {
    console.log('glass id = ', id);
    const confirmDelete = window.confirm('Удалить стекло? ', name);
    if (confirmDelete) {
      console.log('Удаляем тег ', name);

      dispatch(deleteHardwareTag({ tag_id: propsId }));
    } else {
      console.log('Удаление тега отменено');
    }
  };


  return (
    <>
     <div>
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          onBlur={changeTagOnServer}
          className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
        />
        <div>
            <p
              className="pb-2  font-light text-xs text-blue-600 hover:cursor-pointer"
              onClick={() => handleDeleteGlass(propsId)}>
              Удалить
            </p>
          </div>
      </div>
    </>
  );
};

export default Tags;
