import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import ShowerCard from '../components/ShowerCard';
import AddNewShower from '../components/AddNewShower';
import { useDispatch } from 'react-redux';
import { getShowers, selectShowersData } from '../store/slice/showerSlice';
import { useSelector } from 'react-redux';

const ShowerPage = () => {
  const dispatch = useDispatch();
  const [showAddNewShower, setShowAddNewShower] = useState(false);

  const showers = useSelector(selectShowersData);

  useEffect(() => {
    dispatch(getShowers());
  }, []);

  // Эта функция будет передана в AddNewShower для закрытия компонента
  const handleClose = () => {
    setShowAddNewShower(false);
  };
  const sortedShowers = [...showers].sort((a, b) => a.position - b.position);

  return (
    <>
      <Navbar></Navbar>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-6">
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowAddNewShower(true)}>
            Добавить новую кабину{' '}
          </button>
          {showAddNewShower && <AddNewShower onClose={handleClose} />}
        </div>
        <div>
          <p className=" text-center font-semibold text-lg">Все душевые кабины</p>
        </div>
        {sortedShowers.map((shower) => (
          <ShowerCard
            key={shower.cabin_id}
            title={shower.name}
            description={shower.description}
            img_url={shower.img_url}
            note={shower.note}
            cabin_id={shower.cabin_id}
          />
        ))}
      </div>
    </>
  );
};

export default ShowerPage;
