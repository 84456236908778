import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateDimension } from '../store/slice/showerSlice';
import { deleteDimension } from '../store/slice/showerSlice';

const Dimension = ({ propsTitle, propsId, propsDimension }) => {
  const dispatch = useDispatch();
  const [initialTitle, setInitialTitle] = useState(propsTitle);
  const [initialDimension, setInitialDimension] = useState(propsDimension);
  const [title, setTitle] = useState(initialTitle);
  const [dimension, setDimension] = useState(initialDimension);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDimensionChange = (e) => {
    setDimension(e.target.value);
  };

  const changeDimensionOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (title !== initialTitle) {
      dispatch(updateDimension({ title, dimension_id: propsId, dimension }));
      setInitialTitle(title);
      return;
    }
    if (dimension !== initialDimension) {
      dispatch(updateDimension({ title, dimension_id: propsId, dimension }));
      setInitialDimension(dimension);
      return;
    }
  };

  const handleDeleteDimension = (id) => {
    const confirmDelete = window.confirm('Удалить сторону? ', title);
    if (confirmDelete) {
      console.log('Удаляем стекло ', title);
      dispatch(deleteDimension({ dimension_id: propsId }));
    } else {
      console.log('Удаление стороны отменено');
    }
  };

  return (
    <>
      <div className="bg-slate-200 mb-4">
        <div className="ml-2">
          <p className=" font-extralight text-xs pt-1">Название стороны</p>
          <div className="">
            <input
              type="text"
              placeholder="например Сторона А"
              value={title}
              onChange={handleTitleChange}
              onBlur={changeDimensionOnServer}
              className="w-30  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="ml-2 pb-1">
          <p className=" font-extralight text-xs">Размер</p>
          <div className="">
            <input
              type="number"
              placeholder="см."
              value={dimension}
              onChange={handleDimensionChange}
              onBlur={changeDimensionOnServer}
              className="w-30  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
        <div>
            <p
              className="ml-2 pb-1 font-light text-xs text-blue-600 hover:cursor-pointer"
              onClick={() => handleDeleteDimension(propsId)}>
              Удалить
            </p>
          </div>
      </div>
    </>
  );
};

export default Dimension;
