// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDIjwXVDtUqlADkb21B1aKpo1IFT_tCqaM",
  authDomain: "calc5-6ce96.firebaseapp.com",
  projectId: "calc5-6ce96",
  storageBucket: "calc5-6ce96.appspot.com",
  messagingSenderId: "350119481260",
  appId: "1:350119481260:web:06601809a8622dad19f409"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);