import React, { useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { getShowers } from '../store/slice/showerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../hooks/use-auth';
import { Link, Navigate } from 'react-router-dom';
import { selectUserId, setIdDb } from '../store/slice/userSlice';
import { getGlass } from '../store/slice/glassSlice';
import { fetchHardware } from '../store/slice/hardwareSlice';
import { fetchHardwareTags } from '../store/slice/hardwareTagsSlice';

const HomePage = () => {
  const dispatch = useDispatch();
  const { isAuth, email, isLoading } = useAuth();
  const token = useSelector((state) => state.user.token);
  const userEmail = useSelector((state) => state.user.email);
  const firebase_uid = useSelector((state) => state.user.id);

  const userId = useSelector(selectUserId);

  const checkAuth = async () => {
    console.log('Отправил запрос');
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/test-token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuth) {
      const checkAuth = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/test-token`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      };
      const getUserID = async () => {
        console.log('Вызван getUserID');
        try {
          const response = await axios.get(`${process.env.REACT_APP_URL}/api/get_user_id`, {
            params: {
              userEmail,
              firebase_uid,
            },
          });
          dispatch(setIdDb(response.data.userId));
         console.log(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      // Запускаем все проверки
      getUserID();
      //checkAuth();
      
    }
  }, [isAuth]);

  // Проверяет авторизацию на серверной стороне. Добавляет пользователя в БД, если его там нет.
  // На сервере извлекает email из токена

  useEffect(() => {
    dispatch(getShowers({userId}));
    dispatch(getGlass({userId}));
    dispatch(fetchHardware({userId}));
    dispatch(fetchHardwareTags({userId}))
  }, []);




  return isAuth ? (
    <>
    <Navbar></Navbar>
      <div className="text-blue-700 text-xl ">Calc5 - Администратор</div>
      <div className="flex  w-full flex-col">
        <div className="w-full flex  bg-teal-50 ">
          <h1 className="text-justify ml-5">Главная страница</h1>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default HomePage;
