import React, { useEffect, useState } from 'react';
import { addTagToHardware, deleteHardware, updateHardware } from '../store/slice/hardwareSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTagFromHardware } from '../store/slice/hardwareSlice';
import { selectAllTags } from '../store/slice/hardwareTagsSlice';

const Hardwares = ({
  hardware_id,
  propsName,
  propsArticle,
  propsSpecifications,
  propsDescription,
  propsPrice,
  propsLink,
  propsSupplier,
  propsTags,

  isCollapsed: parentIsCollapsed,
  setIsCollapsed: setParentIsCollapsed,
}) => {
  const dispatch = useDispatch();

  const [initialName, setInitialName] = useState(propsName);
  const [initialArticle, setInitialArticle] = useState(propsArticle);
  const [initialSpecifications, setInitialSpecifications] = useState(propsSpecifications);
  const [initialDescription, setInitialDescription] = useState(propsDescription);
  const [initialPrice, setInitialPrice] = useState(propsPrice);
  const [initialLink, setInitialLink] = useState(propsLink);
  const [initialSupplier, setInitialSupplier] = useState(propsSupplier);

  const [name, setName] = useState(initialName);
  const [article, setArticle] = useState(initialArticle);
  const [specifications, setSpecifications] = useState(initialSpecifications);
  const [description, setDescription] = useState(initialDescription);
  const [price, setPrice] = useState(initialPrice);
  const [link, setLink] = useState(initialLink);
  const [supplier, setSupplier] = useState(initialSupplier);

  const allTags = useSelector(selectAllTags);

  const [selectedTag, setSelectedTag] = useState('');

  const [isCollapsed, setIsCollapsed] = useState(true);

  // Эффект для синхронизации локального состояния с состоянием родителя
  useEffect(() => {
    setIsCollapsed(parentIsCollapsed);
  }, [parentIsCollapsed]);

  const toggleCollapse = () => {
    // Если действие инициировано из дочернего компонента, обновляем только его состояние
    setIsCollapsed(!isCollapsed);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleArticleChange = (e) => {
    setArticle(e.target.value);
  };
  const handleSpecificationsChange = (e) => {
    setSpecifications(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };
  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };
  const handleSupplierChange = (e) => {
    setSupplier(e.target.value);
  };

  const changeHardwareOnServer = () => {
    const hardwareData = {
      // соберем все в один объект
      hardware_id,
      name,
      article,
      description,
      price,
      link,
      supplier,
      specifications,
    };
    if (name !== initialName) {
      dispatch(updateHardware({ hardwareData }));
      setInitialName(name);
      return;
    }
    if (article !== initialArticle) {
      dispatch(updateHardware({ hardwareData }));
      setInitialArticle(article);
      return;
    }
    if (description !== initialDescription) {
      dispatch(updateHardware({ hardwareData }));
      setInitialDescription(description);
      return;
    }
    if (specifications !== initialSpecifications) {
      dispatch(updateHardware({ hardwareData }));
      setInitialSpecifications(specifications);
      return;
    }
    if (price !== initialPrice) {
      dispatch(updateHardware({ hardwareData }));
      setInitialPrice(price);
      return;
    }
    if (link !== initialLink) {
      dispatch(updateHardware({ hardwareData }));
      setInitialLink(link);
      return;
    }
    if (supplier !== initialSupplier) {
      dispatch(updateHardware({ hardwareData }));
      setSupplier(supplier);
      return;
    }
  };

  const handleDeleteHardware = (id) => {
    console.log('glass id = ', id);
    const confirmDelete = window.confirm('Удалить фурнитуру? ', name);
    if (confirmDelete) {
      console.log('Удаляем фурнитуру ', name);

      dispatch(deleteHardware({ hardware_id }));
    } else {
      console.log('Удаление фурниутры отменено');
    }
  };
  const handleDeleteTag = (tag_id, hardware_id) => {
    console.log('glass id = ', tag_id);

    dispatch(deleteTagFromHardware({ tag_id, hardware_id }));
  };

  const handleSelectChange = (e) => {
    const tagId = e.target.value;
    const tag = allTags.find((tag) => tag.tag_id.toString() === tagId);

    if (tag) {
      console.log('Добавление тега - ', tagId, ' > ', hardware_id, 'с именем', tag.name);
      dispatch(addTagToHardware({ tag_id: tagId, name: tag.name, hardware_id }));
    } else {
      console.log('Тег с ID', tagId, 'не найден');
    }

    setSelectedTag('');
  };

  return (
    <>
      <div className="bg-slate-200 mt-6 mb-6 rounded-md">
        <div className="pl-2">
          <div className='flex flex-row-reverse mr-4'>
          <button  onClick={toggleCollapse} className=" text-sm text-blue-400 hover:text-blue-700">
            {isCollapsed ? 'больше' : 'меньше'}
          </button>
          </div>
          <div><p>№ {hardware_id}</p></div>
          <p className=" text-sm ">Наименование:</p>
          <input
            type="text"
            value={name}
            onChange={handleNameChange}
            onBlur={changeHardwareOnServer}
            className="font-semibold w-full md:w-11/12 lg:w-4/6 xl:w-6/12   px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className="pl-2">
          <p className=" text-sm ">цена:</p>
          <input
            type="text"
            value={price}
            onChange={handlePriceChange}
            onBlur={changeHardwareOnServer}
            className="font-semibold w-full  md:w-11/12 lg:w-4/6 xl:w-6/12   px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div className={`${isCollapsed ? 'hidden' : 'block'}`}>
          <div className="pl-2 pt-2">
            <p className="font-extralight text-sm pb-1">артикул:</p>
            <input
              type="text"
              value={article}
              onChange={handleArticleChange}
              onBlur={changeHardwareOnServer}
              className="w-full mt-1 md:w-11/12 lg:w-4/6 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="pl-2">
            <p className="font-extralight text-sm pb-1">характеристики:</p>
            <textarea
              type="text"
              value={specifications}
              onChange={handleSpecificationsChange}
              onBlur={changeHardwareOnServer}
              className="w-full mt-1 md:w-11/12 lg:w-4/6 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="pl-2">
            <p className="font-extralight text-sm pb-1">описание:</p>
            <textarea
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              onBlur={changeHardwareOnServer}
              className="w-full mt-1 md:w-11/12 lg:w-4/6 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="pl-2">
            <p className="font-extralight text-sm pt-1">поставщик:</p>
            <input
              type="text"
              value={supplier}
              onChange={handleSupplierChange}
              onBlur={changeHardwareOnServer}
              className="w-full mt-1 md:w-11/12 lg:w-4/6 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="pl-2">
            <p className="font-extralight text-sm pt-1">ссылка:</p>
            <input
              type="text"
              value={link}
              onChange={handleLinkChange}
              onBlur={changeHardwareOnServer}
              className="w-full mt-1 md:w-11/12 lg:w-4/6 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className={`${isCollapsed ? 'hidden' : 'block'}`}>
          <div className='pl-2'>
            <div className="mb-2">Добавить тег</div>
            <select
              onChange={handleSelectChange}
              value={selectedTag}
              defaultValue=""
              className="block w-auto px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value="" disabled>
                Выберите тег
              </option>
              {allTags.map((tag) => (
                <option key={tag.tag_id} value={tag.tag_id}>
                  {tag.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-3 pl-2">
          {propsTags.map((tag) => (
            <div
              key={tag.tag_id}
              className="inline-flex items-center bg-slate-100 text-slate-700 text-sm font-semibold px-1 py-1 rounded m-1">
              <span>{tag.name}</span>
              <button
                onClick={() => handleDeleteTag(tag.tag_id, hardware_id)}
                className="ml-2 bg-slate-100 hover:bg-slate-300 font-thin rounded-full">
                &#10005; {/* Символ крестика */}
              </button>
            </div>
          ))}
        </div>
        <div className={`${isCollapsed ? 'hidden' : 'block'}`}>
          <div className="pl-2">
            <button
              className="pt-2  font-light text-sm text-blue-600 hover:cursor-pointer"
              onClick={() => handleDeleteHardware(hardware_id)}>
              Удалить фурнитуру
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hardwares;
