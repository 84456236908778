import React from 'react';
import Navbar from '../components/Navbar';

const AddShowerPage = () => {
  return (
    <>
      <Navbar></Navbar>
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        
      </div>
    </>
  );
};

export default AddShowerPage;
