import React, { useState } from 'react';
import { addNewTag } from '../store/slice/hardwareTagsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../store/slice/userSlice';

const AddNewTag = ({ onClose }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const [name, setName] = useState('');
  const handleInputNameChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setName(event.target.value);
  };

  function handleAddNewTag() {
    if (name!=='') {
      dispatch(addNewTag({name, user_id: userId})); 
      onClose();      
    }
  }

  return (
    <>
      <div>Новый тип стекла *</div>

      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Название тега"
        onChange={handleInputNameChange}
      />
      <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={handleAddNewTag}>
        Сохранить
      </button>
    </>
  );
};

export default AddNewTag;
