import React, { useState } from 'react';
import { HiOutlineMenu } from 'react-icons/hi';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAuth, signOut } from 'firebase/auth';
import { removeUser } from '../store/slice/userSlice';



const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLogOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    dispatch(removeUser());
  };




  return (
    <nav className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <span className="sr-only">Glarioni</span>
            <Link to="/">
              <img className="h-8 w-auto sm:h-10" src="logo.png" alt="Glarioni" />
            </Link>
          </div>
          {/* Mobile menu button */}
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className=" inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
              onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <AiOutlineMenuFold className="h-6 w-6 text-slate-400" />
              ) : (
                <HiOutlineMenu className="h-6 w-6 text-slate-400" />
              )}
            </button>
          </div>
          {/* Links section */}
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Showers" className="text-base font-medium text-white hover:text-gray-300">
              Кабины
            </Link>
          </div>
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Glass" className="text-base font-medium text-white hover:text-gray-300">
              Стекло
            </Link>
          </div>
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Hardware" className="text-base font-medium text-white hover:text-gray-300">
              Фурнитура
            </Link>
          </div>
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Tags" className="text-base font-medium text-white hover:text-gray-300">
              Тэги
            </Link>
          </div>
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Photo" className="text-base font-medium text-white hover:text-gray-300">
              Фото
            </Link>
          </div>
          <div className={`hidden md:flex space-x-10 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <Link to="/Login"  onClick={handleLogOut} className="text-base font-medium text-white hover:text-gray-300">
              Выход
            </Link>
          </div>
        </div>
      </div>
      {/* Mobile menu, show/hide based on mobile menu state. */}
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link
            to="/Showers"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Кабины
          </Link>
          <Link
            to="/Glass"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Стекло
          </Link>
          <Link
            to="/Hardware"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Фурнитура
          </Link>
          <Link
            to="/Tags"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Тэги
          </Link>
          <Link
            to="/Photo"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Фото
          </Link>
          <Link
            to="/Login"
            onClick={handleLogOut}
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">
            Выход
          </Link>
         
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
