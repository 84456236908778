import React, { useState } from 'react';
import { deleteGlass, updateGlass } from '../store/slice/glassSlice';
import { useDispatch } from 'react-redux';

const Glass = ({ propsName, propsPrice, propsId }) => {
  const dispatch = useDispatch();

  const [initialName, setInitialName] = useState(propsName);
  const [initialPrice, setInitialPrice] = useState(propsPrice);
  const [name, setName] = useState(initialName);
  const [price, setPrice] = useState(initialPrice);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const changeGlassOnServer = () => {
    // Проверяем, изменилось ли значение относительно первоначального
    if (name !== initialName) {
      dispatch(updateGlass({ glass_id: propsId, name: name, price: price }));
      setInitialName(name);
      return;
    }
    if (price !== initialPrice) {
      dispatch(updateGlass({ glass_id: propsId, name: name, price: price }));
      setInitialPrice(price);
      return;
    }
  };

  const handleDeleteGlass = (id) => {
    console.log('glass id = ', id);
    const confirmDelete = window.confirm('Удалить стекло? ', name);
    if (confirmDelete) {
      console.log('Удаляем стекло ', name);

      dispatch(deleteGlass({ glass_id: propsId }));
    } else {
      console.log('Удаление стекла отменено');
    }
  };

  return (
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-2">
      <div class="max-w-sm bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 border border-1">
        <div className="pl-4 pt-4 pb-4">
          <div className="pr-6">
            <p className="font-extralight text-sm pb-1">Наименование:</p>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              onBlur={changeGlassOnServer}
              className="w-full    py-1 px-2  block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <p className="font-extralight text-sm pt-2">Цена за кв.м:</p>
            <input
              type="number"
              value={price}
              onChange={handlePriceChange}
              onBlur={changeGlassOnServer}
              className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <p
              className="pt-2  font-light text-xs text-blue-600 hover:cursor-pointer"
              onClick={() => handleDeleteGlass(propsId)}>
              Удалить
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glass;
