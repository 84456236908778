import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../store/slice/userSlice';
import { addNewHardware } from '../store/slice/hardwareSlice';
import { selectAllTags } from '../store/slice/hardwareTagsSlice';

const AddNewHardware = ({ onClose }) => {
  const user_id = useSelector(selectUserId);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [article, setArticle] = useState('');
  const [link, setLink] = useState('');
  const [supplier, setSupplier] = useState('');
  const [specifications, setSpecifications] = useState('');
  const [description, setDescription] = useState('');

  const allTags = useSelector(selectAllTags);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);

  const handleNameChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setName(event.target.value);
  };
  const handlePriceChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setPrice(event.target.value);
  };
  const handleArticleChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setArticle(event.target.value);
  };
  const handleLinkChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setLink(event.target.value);
  };
  const handleSupplierChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setSupplier(event.target.value);
  };
  const handleSpecificationsChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setSpecifications(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setDescription(event.target.value);
  };

  const handleSelectChange = (e) => {
    const tagId = e.target.value;
    const tagName = allTags.find((tag) => tag.tag_id.toString() === tagId)?.name;

    // Проверяем, что такой тег уже не выбран
    if (!selectedTags.some((tag) => tag.tag_id.toString() === tagId)) {
      setSelectedTags((prevTags) => [...prevTags, { tag_id: tagId, name: tagName }]);
    }
    setSelectedTag('');
  };

  const handleDeleteTag = (tagIdToDelete) => {
    setSelectedTags((prevTags) =>
      prevTags.filter((tag) => tag.tag_id.toString() !== tagIdToDelete),
    );
  };

  function handleAddNewHardware() {
    const hardwareData = {
      // соберем все в один объект
      user_id,
      name,
      article,
      description,
      price,
      link,
      supplier,
      specifications,
      tags: selectedTags,
    };
    if (name !== '' && price !== '') {
      dispatch(addNewHardware({ hardwareData }));
      onClose();
    }
  }
  return (
    <>
      <div>Новая фурнитура *</div>
      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Наименование фурнитуры"
        value={name} // Привязываем значение инпута к стейту
        onChange={handleNameChange} // Устанавливаем обработчик изменений
      />
      <input
        type="number"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="закупочная цена"
        value={price} // Привязываем значение инпута к стейту
        onChange={handlePriceChange} // Устанавливаем обработчик изменений
      />
      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="артикул"
        value={article} // Привязываем значение инпута к стейту
        onChange={handleArticleChange} // Устанавливаем обработчик изменений
      />
      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="ссылка"
        value={link} // Привязываем значение инпута к стейту
        onChange={handleLinkChange} // Устанавливаем обработчик изменений
      />
      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="поставщик"
        value={supplier} // Привязываем значение инпута к стейту
        onChange={handleSupplierChange} // Устанавливаем обработчик изменений
      />
      <textarea
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="характеристики"
        value={specifications} // Привязываем значение инпута к стейту
        onChange={handleSpecificationsChange} // Устанавливаем обработчик изменений
      />
      <textarea
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="описание"
        value={description} // Привязываем значение инпута к стейту
        onChange={handleDescriptionChange} // Устанавливаем обработчик изменений
      />
      <div>
        <div className="mb-2">Добавить тег</div>
        <select
          onChange={handleSelectChange}
          value={selectedTag}
          defaultValue=""
          className="block w-auto px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="" disabled>
            Выберите тег
          </option>
          {allTags.map((tag) => (
            <option key={tag.tag_id} value={tag.tag_id}>
              {tag.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className="mt-3">
          {selectedTags.map((tag) => (
            <div
              key={tag.tag_id}
              className="inline-flex items-center bg-slate-200 text-slate-700 text-sm font-semibold px-1 py-1 rounded m-1">
              <span>{tag.name}</span>
              <button
                onClick={() => handleDeleteTag(tag.tag_id)}
                className="ml-2 bg-slate-200 hover:bg-slate-300 font-thin p-1 rounded-full">
                &#10005; {/* Символ крестика */}
              </button>
            </div>
          ))}
        </div>
      </div>

      <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={handleAddNewHardware}>
        Сохранить
      </button>
    </>
  );
};

export default AddNewHardware;
