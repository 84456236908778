import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { deletePhoto, getAllPhotos, uploadPhoto } from '../store/slice/showerSlice';

const PhotoPage = () => {
  const dispatch = useDispatch();

  // Получаем данные о кабинах и фотографиях из Redux

  // Состояние для хранения выбранной кабины
  const [selectedCabin, setSelectedCabin] = useState(null);
  // Состояние для управления файлом изображения
  const [selectedFile, setSelectedFile] = useState(null);

  // Получение данных о кабинах из Redux
  const cabins = useSelector((state) => state.showers.data);
  const photos = useSelector((state) => state.showers.photos);
  const userId = useSelector((state) => state.user.id_db)

  console.log('userId = ', userId);

  useEffect(() => {
    if (userId) {
      dispatch(getAllPhotos(userId));
    }
  }, [userId, dispatch]);

  // Обработчик выбора кабины
  const handleCabinSelect = (e) => {
    const cabinId = e.target.value;
    setSelectedCabin(cabins.find((cabin) => cabin.cabin_id.toString() === cabinId));
  };

  // Функция для обработки выбора файла
  const handleFileSelect = (event, glassId, hardwareSetId) => {
    // Создаем новый объект File с дополнительными полями для glassId и hardwareSetId
    const fileWithIds = event.target.files[0];
    fileWithIds.glassId = glassId;
    fileWithIds.hardwareSetId = hardwareSetId;
    setSelectedFile(fileWithIds);
  };

  // Функция для отправки файла на сервер
  const handleUpload = () => {
    if (selectedFile) {
      // Диспатчим async thunk с необходимыми параметрами

      dispatch(
        uploadPhoto({
          hardwareSetId: selectedFile.hardwareSetId,
          glassId: selectedFile.glassId,
          file: selectedFile,
        }),
      );
      // Сбросить выбранный файл после попытки загрузки
      setSelectedFile(null);
    } else {
      alert('Выберите файл перед загрузкой.');
    }
  };

  // удаление фото
  const handleDeletePhoto = (photoId, hardwareSetId, glassId) => {
    // Диспатчим экшн для удаления фото
    console.log('Удаление фото');
    dispatch(deletePhoto({ photoId, hardwareSetId, glassId }));
  };

  return (
    <>
      <Navbar></Navbar>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div>------------------------</div>
        <select
          className="border-2 "
          onChange={handleCabinSelect}
          value={selectedCabin?.cabin_id || ''}>
          {cabins.map((cabin) => (
            <option key={cabin.cabin_id} value={cabin.cabin_id}>
              {cabin.name}
            </option>
          ))}
        </select>

        {/* Отображение комбинаций фурнитуры и стекла */}
        {selectedCabin && (
          <>
            {selectedCabin.hardware_sets.map((set) => (
              <div key={set.set_id} className="bg-slate-200 mt-4">
                <h3 className="text-lg">
                  Фурнитура: <span className="font-semibold">{set.name}</span>
                </h3>
                {selectedCabin.glass_types.map((glass) => (
                  <div key={glass.glass_id} className="pt-2">
                    <span>
                      Стекло: <span className="font-medium">{glass.name}</span>
                    </span>
                    {/* Кнопка для добавления фото */}
                    <input
                      type="file"
                      onChange={(event) => handleFileSelect(event, glass.glass_id, set.set_id)}
                    />
                    <button className='bg-slate-300 text-slate-800 active:bg-slate-600  text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ' onClick={handleUpload}>Добавить фото</button>
                    {/* Отображение фотографий для данной комбинации */}
                    <div className="photos flex flex-row">
                      {photos[set.set_id] &&
                        photos[set.set_id][glass.glass_id] &&
                        photos[set.set_id][glass.glass_id].map((photo) => (
                          <div key={photo.photo_id} className="w-32 p-3 relative">
                            <img
                              src={process.env.REACT_APP_URL + '/' + photo.photo_url}
                              alt="Cabin Modification"
                            />
                            {/* Кнопка для удаления фото */}
                            <button
                              className="absolute top-0 right-0 bg-slate-500 text-white"
                              onClick={() =>
                                handleDeletePhoto(photo.photo_id, set.set_id, glass.glass_id)
                              }>
                              X
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PhotoPage;
