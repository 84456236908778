import React, { useState } from 'react';
import { addNewGlass } from '../store/slice/glassSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from '../store/slice/userSlice';

const AddNewGlass = ({ onClose }) => {

    const userId = useSelector(selectUserId);


    const [name, setName] = useState('');
    const [price, setPrice] = useState('');


    const dispatch = useDispatch();

    function handleAddNewGlass() {
      if (name!=='' && price!=='') {
        dispatch(addNewGlass({name, price, userId}));
        onClose();
      }
    }

    const handleInputNameChange = (event) => {
        // Обновляем стейт новым значением из инпута
        setName(event.target.value);
      };
    const handleInputPriceChange = (event) => {
        // Обновляем стейт новым значением из инпута
        setPrice(event.target.value);
      };

  return (
    <>
      <div>Новый тип стекла *</div>

      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Наименование стекла"
        onChange={handleInputNameChange}
      />
      <input
        type="number"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Цена за кв.м"
        onChange={handleInputPriceChange}

      />
      <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
         onClick={handleAddNewGlass} 
      >
        Сохранить
      </button>
    </>
  );
};

export default AddNewGlass;
