import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import Hardwares from '../components/Hardwares';
import AddNewHardware from '../components/AddNewHardware';
import { selectAllTags } from '../store/slice/hardwareTagsSlice';

const HardwarePage = () => {
  const dispatch = useDispatch();
  // Получаем данные о hardware из Redux store
  const hardwares = useSelector((state) => state.hardwares.items);
  const loading = useSelector((state) => state.hardwares.loading);
  const error = useSelector((state) => state.hardwares.error);
  const [showAddNewHardware, setShowAddNewHardware] = useState(false);
  const [areAllCollapsed, setAreAllCollapsed] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredHardwares =
    selectedTags.length > 0
      ? hardwares.filter(
          (hardware) =>
            selectedTags.every((tagId) => hardware.tags.some((tag) => tag.tag_id === tagId)) &&
            (hardware.name.toLowerCase().includes(searchText.toLowerCase()) ||
              hardware.article.toLowerCase().includes(searchText.toLowerCase())),
        )
      : hardwares.filter(
          (hardware) =>
            hardware.name.toLowerCase().includes(searchText.toLowerCase()) ||
            hardware.article.toLowerCase().includes(searchText.toLowerCase()),
        );

  const handleTagFilterChange = (tagId) => {
    setSelectedTags((prevSelectedTags) => {
      if (prevSelectedTags.includes(tagId)) {
        return prevSelectedTags.filter((t) => t !== tagId); // Удаление тега из фильтра
      } else {
        return [...prevSelectedTags, tagId]; // Добавление тега в фильтр
      }
    });
  };

  // Функция для фильтрации фурнитуры по выбранным тегам
  // Функция для фильтрации фурнитуры по выбранным тегам
  /* const filteredHardwares = selectedTags.length > 0
  ? hardwares.filter(hardware =>
      selectedTags.every(tagId =>
        hardware.tags.some(tag => tag.tag_id === tagId)
      )
    )
  : hardwares; */

  const allTags = useSelector(selectAllTags);

  const toggleAllCollapse = () => {
    setAreAllCollapsed(!areAllCollapsed);
  };

  const handleClose = () => {
    setShowAddNewHardware(false);
  };

  return (
    <div>
      <Navbar></Navbar>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-6">
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowAddNewHardware(true)}>
            Добавить фурнитуру
          </button>
          {showAddNewHardware && <AddNewHardware onClose={handleClose} />}
        </div>

        <div className="text-lg font-semibold pt-6">Вся фурнитура</div>
        <button onClick={toggleAllCollapse} className="text-blue-400 hover:text-blue-700">
          {areAllCollapsed ? 'Развернуть все' : 'Свернуть все'}
        </button>

        {/* Фильтр тегов */}
        <div className="flex flex-wrap gap-2 p-4">
          {allTags.map((tag) => (
            <label key={tag.tag_id} className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedTags.includes(tag.tag_id)}
                onChange={() => handleTagFilterChange(tag.tag_id)}
                className="form-checkbox"
              />
              <span className="ml-2">{tag.name}</span>
            </label>
          ))}
        </div>

        <div className="">
          {loading && <p>Загрузка...</p>}
          {error && <p>Ошибка: {error}</p>}

          <div className="mb-4">
            <input
              type="search"
              placeholder="Поиск по наименованию или артикулу"
              value={searchText}
              onChange={handleSearchChange}
              className="p-2 w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          {filteredHardwares.map((hardware) => (
            <div key={hardware.hardware_id}>
              <Hardwares
                hardware_id={hardware.hardware_id}
                propsName={hardware.name}
                propsArticle={hardware.article}
                propsSpecifications={hardware.specifications}
                propsDescription={hardware.description}
                propsPrice={hardware.price}
                propsLink={hardware.link}
                propsSupplier={hardware.supplier}
                propsTags={hardware.tags}
                isCollapsed={areAllCollapsed}
                setIsCollapsed={setAreAllCollapsed}></Hardwares>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HardwarePage;
