import logo from './logo.svg';
import './App.css';
import HomePage from './pages/HomePage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import AddShower from './pages/AddShowerPage';
import GlassPage from './pages/GlassPage';
import HardwarePage from './pages/HardwarePage';
import ShowerPage from './pages/ShowerPage';
import EditShowerPage from './pages/EditShowerPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import TagsPage from './pages/TagsPage';
import PhotoPage from './pages/PhotoPage';

function App() {
  return (
    <Router>
      <div>
        {/* Здесь может быть ваша навигация */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/AddShower" element={<AddShower />} />
          <Route path="/Showers" element={<ShowerPage />} />
          <Route path="/Glass" element={<GlassPage />} />
          <Route path="/Hardware" element={<HardwarePage />} />
          <Route path="/Photo" element={<PhotoPage />} />
          <Route path="/Tags" element={<TagsPage />} />
          <Route path="/EditShower/:cabin_id" element={<EditShowerPage />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
