import React, { useEffect, useState } from 'react';
import { updateQuantity } from '../store/slice/showerSlice';
import { useDispatch } from 'react-redux';

const HardwareTable = ({ hardwareDetails, setId }) => {
  const dispatch = useDispatch();

  // Инициализация состояния количества для каждого элемента фурнитуры
  const [quantities, setQuantities] = useState(
    hardwareDetails.reduce((acc, hardware) => {
      acc[hardware.hardware_id] = 1; // начальное количество каждого элемента равно 1
      return acc;
    }, {}),
  );

  // Обновление состояния количества при изменении hardwareDetails
  useEffect(() => {
    console.log('hardwareDetails = ', hardwareDetails);
    setQuantities(
      hardwareDetails.reduce((acc, hardware) => {
        acc[hardware.hardware_id] = hardware.quantity || 1; // Используйте значение quantity, если оно есть
        return acc;
      }, {}),
    );
  }, [hardwareDetails]); // Зависимость от hardwareDetails

  // Функция для увеличения количества
  const increaseQuantity = (hardware) => {
    const newQuantity = quantities[hardware.hardware_id] + 1;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [hardware.hardware_id]: newQuantity,
    }));

    const incdec = '+'; // increase

    // Отправляем новое количество и set_id на сервер через asyncThunk
    dispatch(
      updateQuantity({
        hardwareId: hardware.hardware_id,
        set_id: setId, // Предполагаем, что set_id доступен в объекте hardware
        quantity: newQuantity,
        incdec,
      }),
    );
  };

  const decreaseQuantity = (hardware) => {
    // Проверяем, чтобы количество не ушло в отрицательные значения
    const newQuantity = Math.max(quantities[hardware.hardware_id] - 1, 0);
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [hardware.hardware_id]: newQuantity,
    }));

    const incdec = '-'; // decrease

    // Если количество больше нуля, отправляем обновленное количество на сервер
    if (newQuantity > -1) {
      dispatch(
        updateQuantity({
          hardwareId: hardware.hardware_id,
          set_id: setId, // Предполагаем, что set_id доступен в объекте hardware
          quantity: newQuantity,
          incdec,
        }),
      );
    }
  };

  const totalSum = hardwareDetails.reduce((total, hardware) => {
    return total + (quantities[hardware.hardware_id] * hardware.price);
  }, 0);
  

  return (
    <>
      <table className="min-w-full leading-normal border-2  mt-4">
        <thead className="border-2">
          <tr className="border-2">
            <th>№</th>
            <th>Наимен.</th>
            <th>Цена</th>
            <th>Кол-во</th>
            <th>Сумма</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {hardwareDetails.map((hardware, index) => (
            <tr key={hardware.hardware_id}>
              <td className="border-2">{hardware.hardware_id}</td>
              <td className="border-2">{hardware.name}</td>
              <td className="border-2">{hardware.price}</td>
              <td className="border-2">{quantities[hardware.hardware_id]}</td>
              <td className="border-2">
                {(quantities[hardware.hardware_id] * hardware.price).toFixed(2)}
              </td>
              <td>
                <button onClick={() => decreaseQuantity(hardware)}>-</button>
                <button onClick={() => increaseQuantity(hardware)} className="ml-4">
                  +
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-right mt-4">
        <span>Сумма: </span>
        {totalSum.toFixed(2)}
      </div>
    </>
  );
};

export default HardwareTable;
