import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { renameSet } from '../store/slice/showerSlice';

const RenameSet = ({ onClose, selectedSetId, cabin_id }) => {
  const dispatch = useDispatch();
  const [setName, setSetName] = useState('');
  const handleInputChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setSetName(event.target.value);
  };

  function handleAddNewShower() {
    dispatch(renameSet({ name: setName, setId: selectedSetId, cabin_id }));
    onClose();
  }

  console.log(' ~ selectedSetId = ',  selectedSetId);

  return (
    <div className="bg-slate-300">
      <div>Переименовать набор</div>
      <input
        type="text"
        className="w-full mt-1 ml-1   py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Новое название"
        value={setName} // Привязываем значение инпута к стейту
        onChange={handleInputChange} // Устанавливаем обработчик изменений
      />
      <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={handleAddNewShower}>
        Сохранить
      </button>
    </div>
  );
};

export default RenameSet;
