import { configureStore } from '@reduxjs/toolkit';
import showersReducer from './slice/showerSlice'; // Импортируйте ваш slice
import userReducer from './slice/userSlice';
import glassReducer from './slice/glassSlice';
import hardwareReducer from './slice/hardwareSlice';
import hardwareTagsReducer from './slice/hardwareTagsSlice';

import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['someReducer'] // if you want to persist only specific reducers
};

const persistedUserReducer = persistReducer({ ...persistConfig, key: 'user' }, userReducer);
const persistedShowerReducer = persistReducer({ ...persistConfig, key: 'showers' }, showersReducer);
const persistedGlassReducer = persistReducer({ ...persistConfig, key: 'glass' }, glassReducer);
const persistedHardwareReducer = persistReducer({ ...persistConfig, key: 'hardware' }, hardwareReducer);
const persistedHardwareTagsReducer = persistReducer({ ...persistConfig, key: 'tags' },  hardwareTagsReducer);
//const persistedGlassReducer = persistReducer({ ...persistConfig, key: 'glass' }, glassReducer);



export const store = configureStore({
  reducer: {
    showers: persistedShowerReducer,
    user: persistedUserReducer,
    glass: persistedGlassReducer,
    hardwares: persistedHardwareReducer,
    tags: persistedHardwareTagsReducer,

  },
});


export const persistor = persistStore(store);
