import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNewDimension } from '../store/slice/showerSlice';

const AddNewDimension = ({ onClose, cabinId }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [dimension, setDimension] = useState('');

  function handleAddNewDimension() {
    if (title !== '' && dimension !== '') {
      dispatch(addNewDimension({ title, dimension, shower_cabin_id: cabinId }));
      onClose();
    }
  }

  const handleInputTitleChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setTitle(event.target.value);
  };
const handleInputDimensionChange = (event) => {
    // Обновляем стейт новым значением из инпута
    setDimension(event.target.value);
  };

  return (
    <>
      <div className='text-xs'>Новая сторона *</div>

      <input
        type="text"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="Название стороны (напр: Сторона А)"
        onChange={handleInputTitleChange}
      />
      <input
        type="number"
        className="w-full mt-1 md:w-1/2 lg:w-1/2 xl:w-6/12  py-1 px-2 pr-11 block border-slate-400 border shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 "
        placeholder="мм."
        onChange={handleInputDimensionChange}
      />
       <button
        className="bg-slate-200 hover:bg-slate-300 text-slate-800 font-normal text-xs py-2 px-4 my-3 rounded"
        onClick={onClose}>
        Отмена
      </button>
      <button
        className="ml-2 bg-blue-500 hover:bg-blue-700 text-slate-100 font-normal text-xs py-2 px-4 my-3 rounded"
         onClick={handleAddNewDimension} 
      >
        Сохранить
      </button>
    </>
  );
};

export default AddNewDimension;
