import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';

// Пример асинхронного действия для получения списка оборудования
export const fetchHardwareTags = createAsyncThunk(
    'hardware/fetchHardwareTagsStatus',
    async ({userId}, { rejectWithValue }) => {
        console.log('🎈 fetchHardware');
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/api/getHardwareTags`, {
                params: {
                  user_id : userId
                }
              });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


/* export const addNewHardwareTag = createAsyncThunk(
    'showers/addNewaddNewHardwareTagSatus',
    async ({ hardwareData }, { rejectWithValue }) => {
        try {
   
            // Отправляем POST-запрос на сервер с новым именем душевой кабины
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewHardwareTag`, {
                hardwareData: hardwareData,
                
            });
            console.log('hard response.data = ', response.data);
            return response.data; // Возвращаем данные из ответа сервера 
        } catch (error) {
            // В случае ошибки возвращаем отклоненное значение
            return rejectWithValue(error.response.data);
        }
    }
);
 */

export const updateHardwareTag = createAsyncThunk(
    'tags/updateHardwareStatus',
    async ({ name, tag_id }, thunkAPI) => {
        console.log('🎈 updateHardwareTag');
       
         try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/updateHardwareTag`, {
                name,
                tag_id
                
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        } 
    }
);

export const deleteHardwareTag = createAsyncThunk(
    'glass/deletedeleteHardwareTagsStatus',
    async ({ tag_id }, thunkAPI) => {
        console.log('🎈 deleteHardwareTags');
          try {
            const response = await axios.delete(`${process.env.REACT_APP_URL}/api/deleteHardwareTag`, {
                data: { tag_id },
            });
            if (response.status === 200) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to update glass.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }  
    }
);

export const addNewTag = createAsyncThunk(
    'tags/addNewTagStatus',
    async ({ name, user_id }, thunkAPI) => {
        console.log('🎈 addNewTag');
               
         try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/addNewTag`, {
                name,
                user_id
            });
            if (response.status === 201) {
                console.log('response.data = ', response.data);
                return response.data;
            } else {
                throw new Error('Failed to add Tag.');
            }
        } catch (error) {
            return thunkAPI.rejectWithValue({ error: error.message });
        }  
    }
);


// Создание слайса
const hardwareTagsSlice = createSlice({
    name: 'tags',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {
        // Здесь могут быть обычные редьюсеры для синхронных действий
        addHardware: (state, action) => {
            state.items.push(action.payload);
        },
        removeHardware: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHardwareTags.pending, (state) => {
          state.loading = true;
        });
  /*       builder.addCase(addNewHardwareTag.fulfilled, (state, action) => {
          state.items.push(action.payload)
        }); */
        builder.addCase(addNewTag.fulfilled, (state, action) => {
            console.log('ggggggggg');
          state.items.push(action.payload)
        });
        builder.addCase(deleteHardwareTag.fulfilled, (state, action) => {
             state.loading = false;
            console.log('вызвался deleteHardware');
            const index = state.items.findIndex(hardware => hardware.tag_id === parseInt(action.payload.tag_id, 10));
            if (index !== -1) {
                // Создание нового массива без элемента, который нужно удалить
                state.items = [
                    ...state.items.slice(0, index),
                    ...state.items.slice(index + 1)
                ];
            }
        });
        builder.addCase(fetchHardwareTags.fulfilled, (state, action) => {
          state.loading = false;
          
          state.items = action.payload;
        });
        builder.addCase(fetchHardwareTags.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        });
        builder.addCase(updateHardwareTag.fulfilled, (state, action) => {
             state.status = 'succeeded';
           
             const index = state.items.findIndex(tags => tags.tag_id === parseInt(action.payload.tag_id, 10));

            if (index !== -1) {
                state.items[index].name = action.payload.name;
               
            } 
        })
      },

});

// Экспорт сгенерированных действий редьюсера
export const { addHardwareTag, removeHardwareTag } = hardwareTagsSlice.actions;
export const selectAllTags = createSelector(
    (state) => state.tags.items, // Селектор, который выбирает часть стейта
    (data) => data // Функция преобразователь, которая возвращает нужные данные
);

// Экспорт редьюсера
export default hardwareTagsSlice.reducer;
