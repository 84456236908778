import React, { useEffect, useState } from 'react';

export const DropdownDimension = ({ onSelect, initialValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  console.log('qwdfwefd');

  const toggleDropdown = () => setIsOpen(!isOpen);
  const [selectedValue, setSelectedValue] = useState(initialValue || 'Выберите опцию');

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const handleSelect = (value) => {
    onSelect(value); // вызываем колбэк с новым значением
    setSelectedValue(value); // обновляем локальное состояние выбранного значения
    setIsOpen(false); // закрываем выпадающий список после выбора
  };
 
  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}>
        {selectedValue} {/* отображаем выбранное значение */}
        <svg
          className="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          {/* ... SVG path для иконки ... */}
        </svg>
      </button>

      {isOpen && (
        <div
          className=" right-0 z-10 w-56 mt-2 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          <div className="py-1" role="none">
            <a
              href="#"
              className="block px-4 py-2 text-sm  text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Сторона A')}>
              Сторона A
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Стороны A, B')}>
              Сторона A, B
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Стороны A, B, C')}>
              Сторона A, B, C
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Стороны A, B, C, E')}>
              Сторона A, B, C, E
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Стороны A, B, C, E, F')}>
              Сторона A, B, C, E, F
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              onClick={() => handleSelect('Стороны A, B, C, E, F, G')}>
              Сторона A, B, C, E, F, G
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownDimension;
